import { ContentCopy, Refresh, Search } from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, TextField, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommandStatus } from '../../../../../core/store/state/utils';

type ToolbarProps = {
  canRenewApiKey: boolean;
  hideApiKey: boolean;
  onRenewApiKey: () => void;
  onSearchProduct: (search: string) => void;
  renewApiKeyStatus: CommandStatus;
  tenantApiKey: string;
};

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  textAlign: 'initial',
}));

export default function Toolbar({
  canRenewApiKey,
  hideApiKey = false,
  onRenewApiKey,
  onSearchProduct,
  tenantApiKey,
}: ToolbarProps) {
  const { t } = useTranslation('administration', { keyPrefix: 'Catalog' });

  let debounceTimer: NodeJS.Timeout | null = null;

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(tenantApiKey);
  };

  const handleSearch = (search: string) => {
    const callback = () => onSearchProduct(search);

    if (debounceTimer) clearTimeout(debounceTimer);

    debounceTimer = setTimeout(callback, 500);
  };

  return (
    <Container>
      <Grid container gap={3} justifyContent="center">
        {!hideApiKey && (
          <Grid display="flex" item xs={12}>
            <Box>
              <TextField
                InputProps={{ style: { fontFamily: 'monospace', width: 380 } }}
                disabled
                label={t('ApiKey')}
                size="small"
                value={tenantApiKey}
              />
            </Box>
            <Box marginLeft="10px">
              <Button color="primary" onClick={handleCopyApiKey} startIcon={<ContentCopy />} variant="contained">
                <span>{t('common:Copy')}</span>
              </Button>
            </Box>
            {canRenewApiKey && (
              <Box marginLeft="10px">
                <Button color="primary" onClick={onRenewApiKey} startIcon={<Refresh />} variant="contained">
                  <span>{t('RenewApiKey')}</span>
                </Button>
              </Box>
            )}
          </Grid>
        )}
        <Grid item lg={hideApiKey ? 10 : 12} xs={12}>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                style: { width: 625 },
              }}
              label={t('common:Search')}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
